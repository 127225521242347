#userbadge {
    display: inline-block;
    background-color: var(--background-color);
    color: var(--foreground-color);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    border-bottom-right-radius: 1.5em;
    border-top-right-radius: 1.5em;
    margin: 0;
    margin-bottom: 0.5em;
    width: 100%;
    min-width: 20em;
    pointer-events: all;
}

#userbadge a {
    text-decoration: none;
    color: var(--foreground-color);
}


#userbadge form {
    width: unset !important;
}

.userstats {
    display: flex;
    align-items: center;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}

.userstats a {
    display: block ruby;
    padding-right: 0.2em;
    margin-bottom: 0.2em;
}

.userstats span {
    display: block ruby;
    padding-right: 0.2em;
    margin-bottom: 0.2em;
}

.userstats svg {
    width: 1em;
    height: 1em;
    border-radius: 0;
    display: block;
}

.userstats img {
    width: 1em;
    height: 1em;
    border-radius: 0;
    display: block;
}

#profile-pic {
    float: left;
    width: 4em;
    height: 4em;
    padding: 0;
    margin: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 500ms linear;
}

.usertext {
    display: block;
    width: max-content;
    margin: 0;

    padding: 0.9em;
    padding-left: 4.7em; /* Should be half of profile-pic's width + actual padding (same as padding-right)*/
    padding-right: 1.5em;
    border-radius: 2em; /*Half border radius width/height*/
    height: 2.2em; /*SHould equal profile-pic height - padding*/
    z-index: 5000;
    text-align: left;
    background-color: var(--background-color);
    color: var(--foreground-color);
    background-size: 100%;

    line-height: 0.75em;
}




.userbadge-login {
    font-weight: bold;
    font-size: large;
    background-color: var(--subtle-detail-color) !important;
    color: var(--subtle-detail-color-contrast);
    height:3em;

    display: inline-block;
    text-align: center;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    border-bottom-right-radius: 1.5em;
    border-top-right-radius: 1.5em;
    margin: 0;

    min-width: 20em;
    pointer-events: all;
}

#userbadge-and-search {
    display: inline-block;
    width: min-content;
}
